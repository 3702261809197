<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" class="modal rounded-xl" width="45%" :height="$vuetify.breakpoint.smAndDown ? '350': '100%'" persistent>
    <v-card class="rounded-xl">
      <v-form @submit.prevent="doAction" ref="form">
        <p class="modal__title text-center pa-5">Establecer fecha</p>
        <div class="d-flex pa-5">
          <div class="modal__sidebar">
            <p class="modal__label px-5 pr-10">Hacer Premium en aplicación Banqueo hasta:</p>
            <v-text-field
              v-model="endDate"
              readonly
              solo
              class="modal__field ma-5"
              ref="setEndDate"
              outlined
              dense
              append-icon="mdi-calendar-month-outline"
              :rules="[rules.required]"
              :error="errorMessages.fecha_fin!=''"
              :error-messages="errorMessages.fecha_fin"
              @focus="errorMessages.fecha_fin = ''"
            ></v-text-field>
          </div>
          <div class="modal__main">
            <v-date-picker
              ref="picker"
              v-model="endDate"
              class="modal__datepicker pa-0"
              header-color="primary"
              locale="es"
              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
            ></v-date-picker>
          </div>
        </div>
        <v-card-actions class="d-flex justify-center pa-5">
          <v-btn @click="close" class="modal__btn mx-1">Cerrar</v-btn>
          <v-btn type="submit" :loading="loading" class="modal__btn modal__btn--save mx-1">Guardar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { required } from '@/utils/rules.js'

export default {
  data: () => ({
    endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    errorMessages: {
      fecha_fin: ''
    },
    rules: {
      required
    },
    loading: false,
    successfulModal: false
  }),
  props: {
    payload: {
      type: Object,
      default: () => {}
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    close () {
      this.$emit('closeCreateModal')
    },
    doAction () {
      if (!this.payload.users_ids.length) {
        const confirm = this.$root.$confirm({
          title: 'Mensaje',
          message: 'Asegúrese de elegir algún usuario.'
        })
        if (confirm) {
          this.close()
        }
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true
          try {
            if (this.id === 1 || this.id === 2) {
              this.payload['fecha_fin'] = new Date(this.endDate)
              axios.post('api/admin/matriculas/users/make_premium/', this.payload)
              .then(response => {
                  if (response.status=== 200) {
                    this.$emit('actionDondeSuccessfully')
                  }
              })
              .catch(error => {
                this.errorMessages.fecha_fin = error.response.data.fecha_fin[0]
              })
            }
          } finally {
            this.loading = false
          }
        }
      }
    }
  },
  watch: {
    endDate () {
      this.errorMessages.fecha_fin = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #455A64;
  }
  &__label {
    font-size: 14px;
    line-height: 16px;
    color: #647485;
  }
  &__sidebar {
    flex-basis: 50%;
  }
  &__main {
    flex-basis: 50%;
  }
  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;
    &--save {
      background: #009EFB !important;
      color: #FFF;
    }
  }
  &__field {
    width: 80% !important;
    // border: 1px solid red !important;
  }
}
</style>
