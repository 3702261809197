<template>
  <v-menu
    v-model="menuAdd"
    bottom
    right
    offset-y
    origin="top right"
    :nudge-width="263"
    :close-on-content-click="false"
    transition="scale-transition"
    content-class="tile tile__filters overflow-hidden py-3"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" exact>
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <div class="filters__container">
      <v-list-group
        v-for="(f, i) in filterTypes"
        :key="i"
        no-action
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ f.type }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="pa-0 ma-0 d-flex justify-start">
          <v-radio-group
            v-model="f.selected"
            class="px-10"
          >
            <v-radio
              v-for="(option, i) in f.options"
              :key="i"
              :label="option"
              :value="option"
              @click.stop="uncheck(f, option)"
              @change="inputChange = true"
            />
          </v-radio-group>
        </v-list-item>
      </v-list-group>
    </div>
  </v-menu>    
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      inputChange: false,
      menuAdd: false,
      seccionesName: [],
      institucionesName: [],
      items: [
        { nombre: 'Por universidad' },
        { nombre: 'Pagos pendientes' },
        { nombre: 'Por sección' },
        { nombre: 'Por tipo de pago' },
      ],
      filterTypes: [
        { id: 1, type: 'Por aplicación', state: null, options: ['Banqueo', 'Flashcards'], selected: null },
        { id: 2, type: 'Por universidad', state: null, options: this.institucionesName, selected: null },
        // { type: 'Por pendientes', state: null, options: [], selected: null },
        { id: 3, type: 'Por sección', state: null, options: this.seccionesName, selected: null },
        { id: 4, type: 'Por tipo de pago', state: null, options: ['Contado', 'En cuotas'], selected: null }
      ],
      previouslyFilter: null
    }
  },
  computed: {
    ...mapState(['secciones', 'instituciones']),
    filterTypesSelected () {
      return this.filterTypes.map(item => item.selected)
    }
  },
  methods: {
    ...mapActions(['fetchSecciones', 'fetchInstituciones']),
    uncheck (val, option) {
      if (this.previouslyFilter && !this.inputChange) {
        const index = val.id - 1
        if (this.previouslyFilter[index].selected === option) {
          this.$set(this.filterTypes[index], 'selected', null)
        } else {
          this.$set(this.filterTypes[index], 'selected', option)
        }
      }
      this.previouslyFilter = this.filterTypes
    }
  },
  watch: {
    filterTypesSelected: {
      deep: true,
      handler () {
        this.$emit('applyFilter', this.filterTypes)
        // localStorage.setItem('filters', JSON.stringify(this.filterTypes))
        this.inputChange = false
      }
    }
  },
  async created () {
    await this.fetchSecciones()
    await this.fetchInstituciones()
    this.seccionesName = this.secciones.map(item => item.nombre)
    this.institucionesName = this.instituciones.map(item => item.nombre)
    this.$set(this.filterTypes.find(item => item.type === 'Por sección'), 'options', this.seccionesName)
    this.$set(this.filterTypes.find(item => item.type === 'Por universidad'), 'options', this.institucionesName)
    this.$set(this.filterTypes.find(item => item.type === 'Por aplicación'), 'selected', this.$route.query.premium || null)
    const findInstitucion = this.institucionesName.find(item => item.id === this.$route.query.institucion)
    if (findInstitucion) {
      this.$set(this.filterTypes.find(item => item.type === 'Por universidad'), 'selected', findInstitucion.nombre || null)
    }
    const findSection = this.seccionesName.find(item => item.id === this.$route.query.seccion)
    if (findSection) {
      this.$set(this.filterTypes.find(item => item.type === 'Por sección'), 'selected', findSection.nombre || null)
    }
    // if (localStorage.getItem('filters')) {
    //   this.filterTypes = JSON.parse(localStorage.getItem('filters'))
    // }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content.tile {
  &__filters {
    background: #FFF !important;
    box-shadow: none !important;
    border: 1px solid #CED4DA;
  }
}
.filters {
  &__title {
    font: normal normal 300 14px/21px Poppins;
    font-size: 14px;
    letter-spacing: 0px;
    color: #54667A;
  }
  &__container {
      max-height: calc(100% - 150px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none !important;
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
}
</style>