<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex align-center justify-space-between">
        <div class="ml-3 d-flex justify-space-between w-100">
          <v-slide-x-transition leave-absolute>
            <div v-if="action">
              <h2 class="font-weight-regular title">Registrados</h2>
              <h6 class="body-2 mb-1 subtitle-2 font-weight-light">Datos de registro</h6>
            </div>
          </v-slide-x-transition>
          <v-slide-x-transition leave-absolute>
            <div v-if="!action">
              <v-btn @click="$emit('closeAction')" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </div>
          </v-slide-x-transition>
          <div class="d-flex justify-center align-center">
            <p class="pa-0 ma-0 unselect">Filtrar por</p>
            <TheUsersFilter @applyFilter="applyFilter"/>
          </div>
        </div>
        <v-spacer></v-spacer>
      </div>
      <v-slide-x-transition leave-absolute>
        <div class="px-5" v-if="!action">
          <p >Acción</p>
          <BaseMenu :items="actions" @execute="doAction"/>
          <br>
          <br>
        </div>
      </v-slide-x-transition>
        <!-- Table -->
      <v-data-table
        :headers="headerArray"
        :items="userFiltered"
        hide-default-footer
        :page.sync="filters.page"
        @click:row="showUserDetail"
        :server-items-length="totalCount"
        :loading="loading"
        :show-select="!action"
        v-model="selected"
      >
        <template v-slot:item.estado="{ item }">
          <span
            class="state px-3 flex-grow-1"
            :class="{'state__listo': item.estado === 'LISTO', 'state__noprocede': item.estado === 'NO_PROCEDE', 'state__recibido': item.estado === 'RECIBIDO'}"
          >{{ capitalize(item.estado) }}</span>
        </template>
        <template v-slot:item.nombre="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.nombres }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.apellidos="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.apellidos }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:footer="slotProps">
          <div class="pagination d-flex justify-space-between ml-auto mr-0 mt-5">
            <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ totalCount }}</p>
            <div>
              <v-btn icon @click="filters.page--" :disabled="filters.page === 1"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <v-btn icon @click="filters.page++" :disabled="next === null"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      <TheSelectDate @actionDondeSuccessfully="success=true" v-model="setEndDate" @closeCreateModal="setEndDate=false" :payload="payload" :id="currentActionId"/>
      <BaseSuccessModal v-model="success" @closeBaseSuccessModal="closeAll" :startTime="success"/>
    </v-card-text>
  </v-card>
</template>

<script>
// import moment from 'moment'
// import NProgress from 'nprogress'
import axios from 'axios'
import TheUsersFilter from '@/components/dashboardComponents/TheUsersFilter'
import TheSelectDate from '@/components/dashboardComponents/TheSelectDate'
import BaseMenu from '@/components/commonComponents/BaseMenu'
import BaseSuccessModal from '@/components/commonComponents/BaseSuccessModal'
import { mapState, mapMutations } from 'vuex'

export default {
  name: "TheUsersTable",
  props: ['search', 'premium', 'seccion', 'institucion', 'page','tipoPago', 'action'],
  components: {
    TheSelectDate,
    TheUsersFilter,
    BaseMenu,
    BaseSuccessModal
  },
  data: () => ({
    success: false,
    totalCount: 0,
    loading: true,
    users: [],
    headerArray: [
      { text: 'Nombre', value: 'nombre', sortable: false, align: 'start' },
      { text: 'Apellido', value: 'apellidos', sortable: false, align: 'start' },
      { text: 'Codigo', value: 'codigo', sortable: false, align: 'center' },
      { text: 'Correo', value: 'correo', sortable: false, align: 'end' }
    ],
    selected: [],
    userFiltered: [],
    singleSelect: false,
    filters: {
      search: '',
      premium: '',
      seccion: '',
      institucion: '',
      page: 1,
      tipoPago: '',
    },
    error: '',
    next: 1,
    actions: [
      // { id: 1, name: 'Eliminar usuarios seleccionados' },
      { id: 1, name: 'Hacer Premium en aplicación Banqueo a los usuarios marcados' },
      { id: 2, name: 'Hacer Premium en aplicación Flash Cards a los usuarios marcados' },
      { id: 3, name: 'Quitar Premium en aplicación Banqueo a los usuarios marcados' },
      { id: 4, name: 'Quitar Premium en aplicación Flash Cards a los usuarios marcados' }
    ],
    endDatePremium: null,
    setEndDate: false,
    payload: {},
    currentActionId: null
  }),
  computed: {
    ...mapState(['searchGlobal', 'secciones', 'instituciones'])
  },
  watch: {
    async searchGlobal () {
      await this.$set(this.filters, 'search', this.searchGlobal)
      this.$router.push(`/dashboard/users/?search=${this.filters.search || ''}&premium=${this.filters.premium}&seccion=${this.filters.seccion}&institucion=${this.filters.institucion}&activo=True&page=${this.filters.page}`).catch(()=>{})
    },
    filters: {
      async handler () {
        await this.reloadData()
        await this.applyFilter()
      },
      deep: true,
    }
  },
  methods: {
    ...mapMutations(['SET_SEARCH']),
    closeAll () {
      this.success = false
      this.setEndDate = false
    },
    extractItems (listOfUsers) {
      return listOfUsers.map(item => {
        return {
          id: item.id,
          nombres: item.first_name || '-',
          apellidos: item.last_name || '-',
          codigo: item.codigo,
          correo: item.email,
        }
      })
    },
    async convertApiResponseToDataFormat (URL) {
      const response = await axios.get(URL)
      const count = response.data.count
      this.totalCount = count % 50 > 0 ? parseInt(count / 50) + 1: parseInt(count / 50)
      const responseData = response.data
      this.next = responseData.next
      const listOfUsersFiltered = await this.extractItems(responseData.results)
      return listOfUsersFiltered
    },
    async reloadData () {
      this.userFiltered = await this.convertApiResponseToDataFormat(`api/admin/matriculas/users/?search=${this.filters.search || ''}&premium=${this.filters.premium || ''}&seccion=${this.filters.seccion || ''}&institucion=${this.filters.institucion || ''}&activo=True&page=${this.filters.page}&tipo_pago=${this.filters.tipoPago || ''}`)
    },
    async applyFilter (filters) {
      if (filters) {
        this.loading = true
        this.filters.page = 1
        this.filters.premium = filters.find(item => item.type === 'Por aplicación').selected || ''
        this.filters.tipoPago = filters.find(item => item.type === 'Por tipo de pago').selected || ''
        const seccionName = filters.find(item => item.type === 'Por sección').selected || ''
        if (seccionName) {
          this.filters.seccion = this.secciones.find(item => item.nombre === seccionName).id || ''
        } else { this.filters.seccion = '' }
        const institucionName = filters.find(item => item.type === 'Por universidad').selected || ''
        if (institucionName) {
          this.filters.institucion = this.instituciones.find(item => item.nombre === institucionName).id || ''
        } else { this.filters.institucion = '' }
      }
      this.$router.push(`/dashboard/users/?search=${this.filters.search || ''}&premium=${this.filters.premium}&seccion=${this.filters.seccion}&institucion=${this.filters.institucion}&activo=True&page=${this.filters.page}&tipo_pago=${this.filters.tipoPago}`).catch(()=>{})
      this.loading = false
    },
    showUserDetail (userRow) {
      this.$router.push({ name: 'UserDetail', params: { id: userRow.id } })
    },
    setConditions (app, users) {
      const payload = {
        app_name: app,
        users_ids: users
      }
      return payload
    },
    async doAction (id) {
      this.currentActionId = id
      const actionName = this.actions.filter(item => item.id === id)[0].name
      const confirm = await this.$root.$confirm({
        title: '',
        message: `¿Estás seguro de realizar la acción "${actionName}" ?`,
        acceptText: 'Si',
        cancelText: 'No'
      })
      if (confirm) {
        const currentUsersSelected = this.selected.map(item => item.id)
        if (id === 1) {
          this.payload = this.setConditions('Banqueo', currentUsersSelected)
          this.setEndDate = true
        } else if (id === 2) {
          this.payload = this.setConditions('Flashcards', currentUsersSelected)
          this.setEndDate = true
        } else if (id === 3) {
          this.payload = this.setConditions('Banqueo', currentUsersSelected)
          axios.post('api/admin/matriculas/users/delete_premium/', this.payload)
            .then(response => {
                if (response.status=== 200) {
                  this.success = true
                }
            })
            .catch(error => {
              console.log(error)
              this.$root.$confirm({
                title: '¡Lo sentimos!',
                message: 'Ha ocurrido un error quitando el premium en Banqueo. Intentelo nuevamente en unos minutos.'
              })
            })
        } else if (id === 4) {
          this.payload = this.setConditions('Flashcards', currentUsersSelected)
          axios.post('api/admin/matriculas/users/delete_premium/', this.payload)
            .then(response => {
                if (response.status=== 200) {
                  this.success = true
                }
            })
            .catch(error => {
              console.log(error)
              this.$root.$confirm({
                title: '¡Lo sentimos!',
                message: 'Ha ocurrido un error quitando el premium en Flashcards. Intentelo nuevamente en unos minutos.'
              })
            })
        }
      }
    }
  },
  async mounted() {
    // await NProgress.start()
    this.loading = true
    if (this.$route.query.search) {
      this.filters.search = this.$route.query.search
    }
    if (this.filters.search) {
      await this.SET_SEARCH(this.filters.search)
    }
    // making the filter when route push due to a change in filters
    if (this.$route.query) {
      this.filters.search = this.search || ''
      this.filters.premium = this.premium || ''
      this.filters.seccion = this.seccion || ''
      this.filters.institucion = this.institucion || ''
      this.filters.page = this.page || 1
      this.filters.tipoPago = this.tipoPago || ''
    }
    this.userFiltered = await this.convertApiResponseToDataFormat(`api/admin/matriculas/users/?search=${this.filters.search || ''}&premium=${this.filters.premium || ''}&seccion=${this.filters.seccion || ''}&institucion=${this.filters.institucion || ''}&activo=True&page=${this.filters.page}`)
    this.loading = false
    // await NProgress.done()
  }
};
</script>

<style lang="scss" scoped>
.table-text {
  color: #647485;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  font-family: "Poppins", sans-serif !important;
}
.state {
  max-height: 23px;
  color: #FFF;
  border-radius: 5px;
  &__recibido {
    background: #1E88E5;
  }
  &__listo {
    background: #26C6DA;
  }
  &__noprocede {
    background: #FC4B6C;
  }
}
</style>
